import { createRoot } from "react-dom/client";
import { Suspense } from "react";
import "./styles.css";
import { App } from "./App";
import { Analytics } from "@vercel/analytics/react";

function Overlay() {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        pointerEvents: "none",
        width: "100%",
        height: "100%",
      }}
    >
      <a
        style={{
          position: "absolute",
          fontWeight: "300",
          bottom: 40,
          left: 40,
          lineHeight: "1.5",
          fontSize: "13px",
          letterSpacing: "0.05rem",
        }}
      >
        Philippines
        <br />
        Manila
      </a>
      <div
        style={{ position: "absolute", top: 40, left: 40, fontSize: "13px" }}
      >
        Ring Diamond House —
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 40,
          right: 40,
          fontSize: "13px",
          lineHeight: "1.5",
          letterSpacing: "0.05rem",
          textAlign: "right",
        }}
      >
        21/02/2024
        <br />— 25/02/2024
      </div>
    </div>
  );
}

createRoot(document.getElementById("root")).render(
  <>
    <Suspense fallback={null}>
      <App />
    </Suspense>
    <Analytics />
    <Overlay />
  </>
);
